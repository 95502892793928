<template>
  <v-col class="mx-auto text-center" sm="8" lg="3" md="4">
    <img src="../assets/dph-app-logo.png" id="login-logo" alt="DPH app" />

    <v-card id="login-card">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="email"
          :rules="validationRules.email"
          label="E-mail"
          required
        ></v-text-field>

        <v-text-field
          v-model="password"
          :rules="validationRules.password"
          label="Lozinka"
          type="password"
          required
        ></v-text-field>

        <div v-show="loading">
          <v-progress-circular
            indeterminate
            color="#1358a3"
          ></v-progress-circular>
        </div>

        <p v-show="authError" class="error-text">{{ authError }}</p>

        <v-btn :disabled="!valid" color="success" class="mr-4" @click="login">
          Ulaz
        </v-btn>
      </v-form>
    </v-card>
  </v-col>
</template>

<script>
import apiRequest from "../helpers/apiRequest";

export default {
  data: () => ({
    password: "",
    email: "",
    valid: false,
    loading: false,
    authError: false,
    validationRules: {
      email: [
        (v) => !!v || "E-mail ne može biti prazan",
        (v) => /.+@.+\..+/.test(v) || "E-mail neispravan",
      ],
      password: [(v) => !!v || "Lozinka ne može biti prazna"],
    },
  }),

  methods: {
    async login(e) {
      e.preventDefault();
      this.$refs.form.validate();

      if (this.valid) {
        this.loading = true;
        this.authError = false;

        try {
          const response = await apiRequest("post", "auth/login", {
            email: this.email,
            password: this.password,
          });

          if (response.token) {
            localStorage.setItem("token", response.token);
            this.$router.push({ name: "dashboard" });
          } else {
            this.authError = "Greška prilikom dohvata tokena.";
          }
        } catch (error) {
          this.authError = error.response.data.message;
        } finally {
          this.loading = false;
        }
      }
    },
  },

  mounted() {
    this.valid = false;
  },
};
</script>

<style lang="scss" scoped>
#login-logo {
  width: 160px;
  margin: 30px 0;
}
#login-card {
  padding: 20px;

  button {
    margin-top: 30px;
    margin-right: 0 !important;
  }
}
</style>
